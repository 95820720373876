<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Logout',

  async created () {
    try {
      await this.authLogout()
      this.$router.push({ name: 'login' })
    }
    catch (e) {
      console.error(e.message)
    }
  },

  methods: {
    ...mapActions({
      authLogout: 'auth/logout'
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
